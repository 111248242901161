
import {computed, defineComponent, onMounted, ref} from "vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import {LoadPanel, PinaFilterObjects} from "@/core/composite/composite";
import {useTaskStore} from "@/store/task.store";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import TaskName from "@/views/task/TaskName.vue";
import UserLink from "@/views/user/UserLink.vue";
import SearchHorizontal from "@/components/common/SearchHorizontal.vue";
import TaskDueDate from "@/views/work/TaskDueDate.vue";
import TeamLink from "@/views/team/TeamLink.vue";

export default defineComponent({
  name: "MyTasks",
  components: {TeamLink, TaskDueDate, SearchHorizontal, UserLink,  TaskName, DateTimeFormat, KtDatatable},

  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("My Tasks",
        [
          {link: false, router: '', text: 'My Tasks'}
        ]
      )
    })

    const store = useTaskStore();
    const filterObjects = ref({owner: true, completed: false, overdue: false, noComplete: true})
    const page = computed(() => store.page);
    const headers = ref<any>([])
    const buildHeaders = (isCompleted: boolean) => {
      if (isCompleted) {
        headers.value = [
          {
            name: 'Created',
            key: 'created',
            sortable: false
          },
          {
            name: 'Name',
            key: 'name',
            sortable: false
          },
          {
            name: 'Assigned',
            key: 'assigned',
            sortable: false
          },
          {
            name: 'Due Date',
            key: 'dueDate',
            sortable: false
          },
          {
            name: 'Completed',
            key: 'completedDt',
            sortable: false
          },
        ]
      } else {
        headers.value = [
          {
            name: 'Created',
            key: 'created',
            sortable: false
          },
          {
            name: 'Name',
            key: 'name',
            sortable: false
          },
          {
            name: 'Assigned',
            key: 'assigned',
            sortable: false
          },
          {
            name: 'Due Date',
            key: 'dueDate',
            sortable: false
          },
        ]
      }
    }
    buildHeaders(false);

    return {
      headers,
      buildHeaders,
      page,
      filterObjects,
      ...PinaFilterObjects(store, filterObjects.value, ['createdBy', 'owner'], 'sort:dueDate:asc'),
      ...LoadPanel()
    }
  },
  methods: {
    searchEvent() {
      if (this.filterObjects.completed) {
        this.buildHeaders(true)
        this.filterObjects.noComplete = false
        this.updateSort('sort:dueDate:desc')
      }else{
        this.buildHeaders(false)
        this.updateSort('sort:dueDate:asc')
      }
      this.updateFilterObject(this.filterObjects);
    },
    discardEvent() {
      this.buildHeaders(false)
      this.filterObjects.completed = false
      this.filterObjects.overdue = false
      this.filterObjects.noComplete = true
      this.updateFilterObject(this.filterObjects);
    },
  }
})
